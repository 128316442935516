$site_info_bg: 			$color-primary;
$site_info_color: 		$white;


.site_info {
	margin-top: 1em;
	border-top: 5px solid $white;
	box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.5);
	background-color: $site_info_bg;
	color: $site_info_color;
	font-size: 0.9em;

	@include media-breakpoint-up(md) {
		.info_company { @include make-col(4); }
		.info_link { @include make-col(8); }
	}

	@include media-breakpoint-up(lg) {
		.info_company { @include make-col(3); }
		.info_link { @include make-col(9); }
	}

}

	.info_company {
		a { color: $white; text-decoration: none; }
			a:not(.phone):hover { text-decoration: underline; }
		.company_name { display: block; font-size: 1.2em; }
		.phone {
			font-size: 1.7em;
			font-weight: 800;
			color: $white;
		}
		.company_license small { display: block; }

	}

.info_link {

	margin-top: 1em;

	@include media-breakpoint-up(sm) {
		overflow: hidden;
		ul {
			display: block;
			float: left;
			width: 30%;
		}
	}

	@include media-breakpoint-only(md) {
		ul { width: 40%; }
	}

	a {
		text-decoration: none;
		color: $white;
	}

	li {
		position: relative;
		display: block;
		clear: both;
		overflow: hidden;
		line-height: 1em;
	}

		img {
			display: inline;
			vertical-align: middle;
			width: 2.5em; height: 2.5em;
			margin: 0 0.25em 0 0;
			padding: 0.625em;
			border-radius: 50%;
			border: 2px solid $white;
			background-color: rgba(255,255,255,0.3);
			@include transition(background ease-in 300ms);
		}

		a:hover img {
			background-color: rgba(0,0,0,0.5);
			@include transition(background ease-in 300ms);
		}	

	li.link-fb a:hover img { background-color: $blue; }

	li.link-g a:hover img { background-color: #DC4A38; }

	li.link-yelp a:hover img { background-color: #BE2418; }

}




.copyright {

	font-size: 0.85rem;
	line-height: 1.1em;
	border-top: 2px solid $white;
	background-color: $color-secondary;
	color: $white;

	a {
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.2em;
		text-decoration: none;
		color: $white;
	}

	@include media-breakpoint-up(sm) {
		font-size: 1rem;
		ul > li { display: inline-block; margin: 0 0.5rem 0 0 !important; }
		a { line-height: 1.1em; }
	}

}