$nav_pills_radius: 				4px;
$nav_pills_link_border:			1px solid $color-text !default;

.nav--pills {

	.nav_menu > .menu_item > .menu_link {
		border-radius: $nav_pills_radius;
	}

	.nav_menu > .open > .menu_link {
		border-radius: $nav_pills_radius $nav_pills_radius 0 0;
	}

}

