$carousel-text-shadow:                              0 1px 2px rgba(0,0,0,.6) !default;
$carousel-control-color:                            #fff !default;
$carousel-control-width:                            15% !default;
$carousel-control-opacity:                          .5 !default;
$carousel-control-font-size:                        20px !default;
$carousel-indicator-active-bg:                      #fff !default;
$carousel-indicator-border-color:                   #fff !default;
$carousel-caption-color:                            #fff !default;
$carousel-caption-bg:                               rgba(0,0,0,0.8);



.carousel-fade {
    .carousel-inner {
        .item { transition-property: opacity; }
        .item, .active.left, .active.right { opacity: 0.5; }
        .active, .next.left, .prev.right { opacity: 1; }
        .next, .prev, .active.left, .active.right { left: 0; transform: translate3d(0, 0, 0); }
    }
    .carousel-control { z-index: 2; }
}

.carousel-caption {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    padding: 1.25em;
    background-color: $carousel-caption-bg;
    color: $carousel-caption-color;
    text-align: center;
    text-shadow: $carousel-text-shadow;
    text-transform: uppercase;
    & .btn { text-shadow: none; }

    .caption_title {
        margin: 0;
        font-size: 2rem;
        font-weight: 800;
    }

    @include media-breakpoint-up(lg) {
        text-align: left;
        .caption_title { font-size: 2.75rem; }
    }

}



// BOOTSTRAP START

.carousel { position: relative; }

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;

    > .item {
        display: none;
        position: relative;
        @include transition(.6s ease-in-out left);

        > img,
        > a > img {
            @include img-responsive;
            line-height: 1;
        }

        @media all and (transform-3d), (-webkit-transform-3d) {
            @include transition-transform(0.6s ease-in-out);
            @include backface-visibility(hidden);
            @include perspective(1000px);

            &.next,
            &.active.right {
                @include translate3d(100%, 0, 0);
                left: 0;
            }
            
            &.prev,
            &.active.left {
                @include translate3d(-100%, 0, 0);
                left: 0;
            }

            &.next.left,
            &.prev.right,
            &.active {
                @include translate3d(0, 0, 0);
                left: 0;
            }
        }
    }

    > .active, > .next, > .prev { display: block; }

    > .active { left: 0; }

    > .next, > .prev {
        position: absolute;
        top: 0;
        width: 100%;
    }

    > .next { left: 100%; }
    > .prev { left: -100%; }
    > .next.left, > .prev.right { left: 0; }

    > .active.left { left: -100%; }
    > .active.right { left: 100%; }

}

.carousel-control {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: $carousel-control-width;
    @include opacity($carousel-control-opacity);
    font-size: $carousel-control-font-size;
    color: $carousel-control-color;
    text-align: center;
    text-shadow: $carousel-text-shadow;
    background-color: rgba(0, 0, 0, 0);

    &.left { @include gradient-horizontal($start-color: rgba(0,0,0,.5), $end-color: rgba(0,0,0,.0001)); }
    &.right {
        left: auto;
        right: 0;
        @include gradient-horizontal($start-color: rgba(0,0,0,.0001), $end-color: rgba(0,0,0,.5));
    }

    &:hover,
    &:focus {
        outline: 0;
        color: $carousel-control-color;
        text-decoration: none;
        @include opacity(.9);
    }

    .icon-prev,
    .icon-next,
    .glyphicon-chevron-left,
    .glyphicon-chevron-right {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        z-index: 5;
        display: inline-block;
    }

    .icon-prev,
    .glyphicon-chevron-left {
        left: 50%;
        margin-left: -10px;
    }

    .icon-next,
    .glyphicon-chevron-right {
        right: 50%;
        margin-right: -10px;
    }

    .icon-prev,
    .icon-next {
        width:  20px;
        height: 20px;
        line-height: 1;
        font-family: serif;
    }

    .icon-prev:before { content: '\2039'; }
    .icon-next:before { content: '\203a'; }

}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;

    li {
        display: inline-block;
        width:  10px;
        height: 10px;
        margin: 1px;
        text-indent: -999px;
        border: 1px solid $carousel-indicator-border-color;
        border-radius: 10px;
        cursor: pointer;
        background-color: #000 \9; // IE8
        background-color: rgba(0,0,0,0); // IE9
    }

    .active {
        margin: 0;
        width:  12px;
        height: 12px;
        background-color: $carousel-indicator-active-bg;
    }

}

@include media-breakpoint-up(md) {
    .carousel-control {
        .glyphicon-chevron-left,
        .glyphicon-chevron-right,
        .icon-prev, .icon-next {
            width: ($carousel-control-font-size * 1.5);
            height: ($carousel-control-font-size * 1.5);
            margin-top: ($carousel-control-font-size / -2);
            font-size: ($carousel-control-font-size * 1.5);
        }
        .glyphicon-chevron-left,
        .icon-prev { margin-left: ($carousel-control-font-size / -2); }
        .glyphicon-chevron-right,
        .icon-next { margin-right: ($carousel-control-font-size / -2); }
    }
    .carousel-indicators { bottom: 20px; }
}


    
