$reviews-bg: 							$color-review !default;
$reviews-color: 						$white !default;

.review p {
    position: relative;
    margin-bottom: 2em;
    padding: 2em 1em;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1.5em 1em -1em rgba(0,0,0,.3);
    color: $color-text;
}

    .review__stars {
    	max-width: 150px;
    	margin: 0 auto 10px;
    }

	.review p:after {
	    content: "";
	    position: absolute;
	    bottom: -1em;
	    left: 25%;
	    border-width: 1em 1em 0;
	    border-style: solid;
	    border-color: #fff transparent;
	    display: block;
	    width: 0;
	}

	.review cite {
		display: block;
		margin: -0.5em 0.5em 0 0;
		padding: 0 1em 0 0;
		font-size: 0.85rem;
		line-height: 1.2em;
		text-align: right;
		border-right: 5px solid $color-review;

		strong { font-size: 1rem; }
		
		small { font-size: 0.85rem; }

		@include media-breakpoint-up(sm) {
			margin-top: -1em;
			margin-right: 1.5em;
		}

	}



.block-main.reviews {

	border-top: 5px solid #fff;
	border-bottom: 5px solid #fff;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
	background-color: $reviews-bg;
	color: $reviews-color;

	.block-title {
		margin: 0 0 0.5em;
		font-size: 1.8rem;
		font-weight: 800;
		text-transform: uppercase;
		text-align: center;
		text-shadow: 0 1px 4px rgba(0,0,0,0.5);
	}

	.review { text-align: center }

	@include media-breakpoint-only(md) {
		.reviews-wrapper {
			overflow: hidden;
			.review {
				float: left;
				width: 50%;
				&:nth-child(odd) { padding-right: 8px; }
				&:nth-child(even) { padding-left: 8px; }
				&:last-child {
					float: none;
					clear: both;
					width: 100%;
					padding-right: 0;
				}
				p { padding: 2em; }
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.reviews-wrapper {
			overflow: hidden;
			.review {
				float: left;
				width: (97 / 3) * 1%;
				margin-right: 1.5%;
				&:nth-child(3n) { margin-right: 0; }
				&:nth-child(3n-2) { clear: both; }
				p { padding: 2em; }
			}
		}
	}

	@include media-breakpoint-up(xl) {}

}



.reviews_main {
	
	.review {
		margin-bottom: 2em;

		p {
			border-left: 10px solid $color-review;
		}

	}

}
