a {
	font-style: normal;
	text-decoration: underline;
	color: $color-link;
	&:hover,
	&:focus {
		color: darken($color-link, 15%);
		text-decoration: none;
	}
	&:focus {
		outline: 1px solid lighten($color-secondary, 25%);
		//box-shadow: 0 0 3px 3px rgba($color-link, 0.5);
	}
}


.phone {
	text-decoration: none;
	cursor: default;
	color: $color-phone;
	&:hover {
		text-decoration: none;
		color: $color-phone;
		outline: none;
		cursor: default;
	}
}

	.phone.mask {
		color: $color-text;
		&:hover {
			outline: none;
			color: $color-text;
		}
	}
