$logo_width: 			200px;
$logo_height: 			129px;
$logo_width-sm: 		278px;
$logo_height-sm:		179px;
$logo_width-max: 		360px;
$logo_path: 			url("/_/images/layout/tv-plumbing.png");

.site_branding {}

	.branding_logo {
		display: block;
		box-sizing: border-box;
		max-width: $logo_width-max;
		margin: 0 auto;
		//transform: scale(0.97);
		@include transition(all ease-in-out 200ms);

		svg {
			#svg_best_b { fill:url(#b_gradient); }
			#svg_best_e { fill:url(#e_gradient); }
			#svg_best_s { fill:url(#s_gradient); }
			#svg_best_t { fill:url(#t_gradient); }
			#svg_rsbg { fill: $color-secondary; }
			#svg_rs { fill: $white; }
		}

		&:hover {
			//transform: scale(1);
			@include transition(all ease-in-out 200ms);

			svg {
				#svg_best_b { fill: $color-primary; }
				#svg_best_e { fill: $color-primary; }
				#svg_best_s { fill: $color-primary; }
				#svg_best_t { fill: $color-primary; }
			}
		}

	}

