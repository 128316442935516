$jumbo-bg: 			$white;

.jumbo {

	background-color: $jumbo-bg;
	padding: 0;

	.carousel-caption { opacity: 0; }

	.form {
		border: none;
		@include media-breakpoint-down(md) {
			border-top: 5px solid $white;
		}
	}

	@include media-breakpoint-up(sm) {
		.carousel-caption { opacity: 1; }
	}

	@include media-breakpoint-only(md) {
		.form {
			overflow: hidden;
			padding: 1rem 1rem 2rem;
			position: relative;
		}
		.form_title {
			margin: -1rem -1rem 1.25em;
		}
		.form_name,
		.form_phone,
		.form_email {
			float: left;
			clear: both;
			width: 50%;
		}
		.form_comments {
			position: absolute;
			top: 96px; right: 1rem;
			float: right;
			width: 48%;
			textarea { height: 127px; }
		}
		.form_spam {
			clear: both;
			float: left;
			width: 310px;
		}
		.form_submit {
			float: left;
			padding-top: 10px;
			.btn { min-width: 240px; }
		}
	}

	@include media-breakpoint-up(md) {
		.carousel .item { max-height: 420px; }
	}

	@include media-breakpoint-up(lg) {

		position: relative;

		.carousel {
			width: 100%;
			margin: 0 auto;			
		}

		.carousel .item,
		.carousel .item > img {
			min-height: 580px;
			max-height: 580px;
		}

		.carousel .item > img {
			width: 100%;
			margin: 0 auto;
		}

		.jumbo_conversion {
			position: absolute;
			top: 1em; right: 0; left: 0;
			max-width: 1140px;
			margin: 0 auto;
			padding-right: 1em;
			.form {
				float: right;
				width: 328px;
				.btn { width: 100%; }
			}
		}

	}

	@media (min-width: 1600px) {
		.carousel,
		.carousel .item > img,
		.carousel .carousel-caption {
			max-width: 1600px;
		}		
		.fade_slider {
			position: absolute;
			top: 0; right: 0; left: 0; bottom: 0;
			max-width: 1600px;
			margin: 0 auto;
			background: linear-gradient(to right, $jumbo-bg 1%, transparent 4%, transparent 96%, $jumbo-bg 99%);
			background: radial-gradient(circle, transparent 81%, $jumbo-bg 88%);
			background-size: 100% 145%;
			background-position: center center;
		}		
	}

}

