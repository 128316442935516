$contact_title_color: 				$color-secondary;
$contact_phone_color: 				$color-primary;

.site_contact {

	text-align: center;

	@include media-breakpoint-only(md) {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	@include media-breakpoint-up(md) {
		text-align: right;
	}

}

	.contact_title {
		display: block;
		text-transform: uppercase;
		font-size: 1.5rem;
		font-weight: 800;
		line-height: 1em;
		letter-spacing: -0.04em;
		color: $contact_title_color;
		@include media-breakpoint-up(md) {
			font-size: 1.75rem;
		}
	}

	.contact_phone {
		font-size: 2.25rem;
		font-weight: 800;
		line-height: 1em;
		letter-spacing: -0.04em;
		color: $contact_phone_color;
		&:hover { color: $contact_phone_color; outline: none; }
		@include media-breakpoint-up(md) {
			font-size: 2.5rem;
		}
	}