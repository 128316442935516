$announce-bg: 				$color-secondary !default;
$announce-color: 			$white !default;

.announce {

	padding: 12px 0;
	border-bottom: 0.25rem solid $white;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
	background-color: $announce-bg;
	color: $announce-color;

	p {
		margin-bottom: 0.5em;
		font-weight: 800;
		text-transform: uppercase;
		&:last-child { margin-bottom: 0; }
	}

}

	[class*="announce_"] {
		text-align: center;
		@include media-breakpoint-up(md) {
			p {
				float: left;
				margin: 0;
			}
		}
	}

	.announce_2 {
		@include media-breakpoint-up(md) {
			p { width: percentage(1/2); }
		}
	}

	.announce_3 {
		@include media-breakpoint-up(md) {
			p { width: percentage(1/3); }
		}		
	}
	
	.announce_4 {
		@include media-breakpoint-up(md) {
			p { width: percentage(1/4); }
		}		
	}