.block-main.socials {

	padding: 2em 0 1.75em;

	a {
		position: relative;
		display: block;
		padding: 1rem;
		text-decoration: none;
		background-color: $color-link;
		color: $white;

		&:hover span {
			right: 0;
			width: 100%;
			@include transition(all ease-in-out 300ms);
		}

	}

		span {
			display: block;
			position: absolute;
			z-index: 100;
			top: 0;
			left: 0;
			bottom: 0;
			width: 45px;
			background-color: rgba(0,0,0,0.2);
		}

			img {
				vertical-align: middle;
				max-width: 24px;
				margin: 1rem auto 0;
			}

		strong {
			position: relative;
			z-index: 500;
		}

			.facebook {
				a {
					background-color: #3c5a99;
					&:hover span img { margin-left: 1.05rem; }
				}
				img { max-width: 12px; }
			}

			.google {
				a {
					background-color: #dc4e41;
					&:hover span img { margin-left: 0.55rem; }
				}
				img { max-width: 28px; }
			}

			.twitter {
				a {
					background-color: #00aced;
					&:hover span img { margin-left: 0.68rem; }
				}
			}

			.yelp {
				a {
					background-color: #c50802;
					&:hover span img { margin-left: 0.75rem; }
				}
				img { max-width: 21px; }
			}

	strong {
		display: inline-block;
		margin-left: 40px;
	}

	@include media-breakpoint-only(sm) {
		li {
			float: left;
			width: 49.5%;
			margin-bottom: 1%;
			&:nth-child(even) { float: right; }
		}
	}

	@include media-breakpoint-up(md) {
		li {
			float: left;
			width: ((100-3) / 4) * 1%;
			margin-right: 1%;
			&:nth-child(4n) { margin-right: 0; }
			&:nth-child(4n-3) { clear: both; }
		}
	}

}